<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-divider v-if="['divider'].includes(type)" />
      <h5
        v-if="['divider'].includes(type)"
        class="subtitle-1 mt-3"
        v-html="label"
      />

      <v-text-field
        v-if="['text', 'password', 'email', 'number'].includes(type)"
        filled
        :dense="dense"
        :value="value"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :label="label || name"
        :hint="hint"
        :persistent-hint="persistentHint"
        :required="required"
        :clearable="clearable"
        :prefix="prefix"
        :suffix="suffix"
        :append-icon="icon"
        :prepend-inner-icon="prependInnerIcon"
        :step="step"
        :min="min"
        :max="max"
        :counter="counter"
        :hide-details="hideDetails"
        @input="onInput"
        @click:append="onIconClick"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-text-field>

      <v-text-field
        v-if="['date', 'time', 'datetime-local'].includes(type)"
        filled
        :dense="dense"
        :value="value"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :label="label || name"
        :hint="hint"
        :persistent-hint="persistentHint"
        :required="required"
        :clearable="clearable"
        :prefix="prefix"
        :suffix="suffix"
        :append-icon="icon"
        :min="min"
        :max="max"
        :hide-details="hideDetails"
        @input="onInput"
        @click:append="onIconClick"
      ></v-text-field>

      <v-switch
        v-if="['switch'].includes(type)"
        :input-value="value"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :label="label || name"
        :hint="hint"
        :persistent-hint="persistentHint"
        :required="required"
        :dense="dense"
        @change="onChange"
      ></v-switch>

      <v-checkbox
        v-if="['checkbox'].includes(type)"
        filled
        :value="value"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :label="label || name"
        :hint="hint"
        :persistent-hint="persistentHint"
        :required="required"
        :clearable="clearable"
        :dense="dense"
        @click="onInput"
      ></v-checkbox>

      <v-textarea
        v-if="['textarea'].includes(type)"
        :value="value"
        filled
        :error-messages="errors"
        :disabled="disabled"
        :readonly="readonly"
        :label="label || name"
        :hint="hint"
        :persistent-hint="persistentHint"
        :required="required"
        :dense="dense"
        :clearable="clearable"
        :rows="rows"
        :counter="counter"
        @input="onInput"
      ></v-textarea>

      <v-select
        v-if="['select'].includes(type)"
        filled
        :value="value"
        :items="values"
        :label="label || name"
        :hint="hint"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :persistent-hint="persistentHint"
        :required="required"
        :clearable="clearable"
        :dense="dense"
        :multiple="multiple"
        :chips="chips"
        :attach="attach"
        @input="onInput"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-select>

      <v-autocomplete
        v-if="['autocomplete'].includes(type)"
        filled
        :value="value"
        :label="label || name"
        :items="values"
        :hint="hint"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errors"
        :persistent-hint="persistentHint"
        :required="required"
        :clearable="clearable"
        :dense="dense"
        :chips="!single"
        :deletable-chips="deletableChips"
        :small-chips="!single"
        :multiple="!single"
        @input="onInput"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-autocomplete>

      <!--  time picker -->
      <v-menu
        v-if="['datepicker'].includes(type)"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            filled
            :dense="dense"
            :value="valueToDatePickerText(value)"
            :type="type"
            :disabled="disabled"
            :error-messages="errors"
            :label="label || name"
            :hint="hint"
            :persistent-hint="persistentHint"
            :required="required"
            :clearable="clearable"
            :prefix="prefix"
            :suffix="suffix"
            :hide-details="hideDetails"
            append-icon="event"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="valueToDatePickerValue(value)"
          :range="range"
          :multiple="multiple"
          :max="maxToday ? today : false"
          @input="onInput"
        />
      </v-menu>

      <!--  time diff -->
      <v-menu
        v-if="['datediff'].includes(type)"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            filled
            :dense="dense"
            :value="valueToDatePickerText(timeDiffValue)"
            :type="type"
            :disabled="disabled"
            :error-messages="errors"
            :label="label || name"
            :hint="hint"
            :persistent-hint="persistentHint"
            :required="required"
            :clearable="clearable"
            :prefix="prefix"
            :suffix="suffix"
            append-icon="event"
            readonly
            v-bind="attrs"
            v-on="on"
            :hide-details="hideDetails"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="valueToDatePickerValue(timeDiffValue)"
          :max="today"
          @input="onInput"
        />
      </v-menu>
    </ValidationProvider>
  </v-col>
</template>

<script>
import moment from 'moment-timezone'
import { setInteractionMode } from 'vee-validate'
import { isString, method, head, isDate, flow, identity } from 'lodash'
import { when } from '@/src/utils/functions'

setInteractionMode('eager')

export default {
  name: 'FormItem',
  components: {},
  props: {
    type: {
      type: String,
      default: 'text',
      validator(val) {
        return [
          'divider',
          'text',
          'password',
          'email',
          'number',
          'date',
          'time',
          'datetime-local',
          'checkbox',
          'select',
          'textarea',
          'autocomplete',
          'datepicker',
          'datediff',
          'switch',
        ].includes(val)
      },
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    suffix: {
      type: String,
      required: false,
      default: null,
    },
    prefix: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    prependInnerIcon: {
      type: String,
      required: false,
      default: null,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    maxToday: {
      type: Boolean,
      default: false,
    },
    /*
    This is used only for autocomplete meaning
    that an autocomplete that is usually multiple
    is forced being single (Can only select 1)
    */
    single: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: false,
    },
    date_format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    value: {
      type: [String, Array, Number, Boolean],
      default: null,
    },
    values: {
      type: Array,
      default() {
        return []
      },
    },
    step: {
      type: [String, Number],
      default: null,
    },
    range: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    timeDiff: {
      type: [String, Number],
      default: 'months',
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    timeDiffValue: null,
  }),
  computed: {
    required() {
      if (isString(this.rules))
        return this.rules !== null && this.rules.indexOf('required') > -1

      return null
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
  },
  methods: {
    onInput(value) {
      const isDatePicker = ['datepicker'].includes(this.type)
      const isDateDiff = ['datediff'].includes(this.type)
      let valueToEmit = value

      if (isDatePicker) {
        const isSingleDate = !(this.range || this.multiple)

        if (isSingleDate) {
          this.menu = false
        } else {
          // Allow two dates on range and Infinity on multiple
          const maxSelection = this.range ? 2 : Infinity

          // Sort multiple dates in order
          valueToEmit = valueToEmit.sort((a, b) => new Date(a) - new Date(b))

          if (value.length >= maxSelection) {
            this.menu = false
          }
        }

        // Disabilita le date future
        // const today = new Date();
        // valueToEmit = valueToEmit.filter(date => new Date(date) <= today);

      }

      if (isDateDiff) {
        this.timeDiffValue = value
        valueToEmit = moment().diff(moment(value), this.timeDiff)
      }
      
      this.$emit('input', valueToEmit)
    },
    onChange(value) {
      this.$emit('input', !!value)
    },

    async onIconClick() {},

    dateToYYYYMMDD: flow([
      when(isDate, method('toISOString')),
      method('split', 'T'),
      head,
    ]),

    valueToDatePickerValue(value) {
      if (this.type === 'datediff') {
        const date = moment().add(value, this.timeDiff)

        return when(identity, this.dateToYYYYMMDD)(date)
      }

      if (Array.isArray(value)) return value.map(this.dateToYYYYMMDD)

      return when(identity, this.dateToYYYYMMDD)(value)
    },

    valueToDatePickerText(value) {
      if (!value) return null
      else {
        if (Array.isArray(value)) {
          return value
            .map((date) => moment(date).format(this.date_format))
            .join(' - ')
        } else {
          return moment(value).format(this.date_format)
        }
      }
    },
  },
}
</script>
